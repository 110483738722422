:root {
  --header-height: 80px;
  --bs-heading-color: #343434 !important;
}

body {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  color: #7c7c7c !important;
  @media (max-width: 768px) {
    font-size: 14px !important;
  }
}

button {
  background: 0 0;
  box-shadow: none;
  border: 0;
  padding: 0;
  cursor: pointer;
}

main {
  margin-top: calc(var(--header-height) * -1);
}

img {
  width: auto;
  max-width: 100%;
  height: auto;
}

.header {
  align-items: center;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: transparent;
  transition: background 0.3s ease-in-out;
}

.header.sticky {
  box-shadow: 0 2px 2px -2px #0003;
  background-color: #131517be;
}

.header .container {
  height: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  padding: 0 30px;
}

.header-logo {
  width: auto;
  height: 32px;
  max-height: 32px;
}

.header-menu {
  height: var(--header-height);
  margin-left: auto;
}

.header-menu .nav {
  display: block;
}

.header-menu .nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.header .header-menu .nav .nav-item {
  position: relative;
  display: inline-block;
  height: 100%;
  margin: 0 0 0 20px;
  text-align: left;
}

.header .header-menu .nav .nav-item .nav-link {
  color: #fff;
  display: inline-block;
  line-height: var(--header-height);
  padding: 0;
  letter-spacing: -0.2px;
}

.nav .nav-item .nav-link {
  padding: 0;
  color: #7c7c7c;
  -webkit-transition: linear 0.1s;
  transition: linear 0.1s;
}
.header .header-menu .nav .nav-item:hover .nav-link:before {
  height: 6px;
}

.header .header-menu .nav .nav-item .nav-link:before {
  position: absolute;
  right: 0;
  bottom: 22px;
  left: 0;
  content: "";
  background: rgba(255, 255, 255, 0.1);
  height: 0;
  -webkit-transition: all 0.14s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.14s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.header .header-menu.show {
  left: 0;
}

.header .header-toggle {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 28px;
  margin-left: auto;
  cursor: pointer;
}

.header .header-toggle span {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.header .header-toggle span,
.header .header-toggle span:after,
.header .header-toggle span:before {
  position: absolute;
  left: 0;
  background: #fff;
  content: "";
  width: 100%;
  height: 2px;
  border-radius: 2px;
  -webkit-transition: linear 0.1s;
  transition: linear 0.1s;
}

.header .header-toggle span:before {
  top: -7px;
}

.header .header-toggle span:after {
  bottom: -7px;
}

.header-toggle.toggle-close span {
  background: 0 0;
}

.header .header-toggle.toggle-close span:before {
  top: 0;
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.header .header-toggle.toggle-close span:after {
  top: 0;
  bottom: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.header-section {
  width: 100%;
  height: 100dvh;
  position: relative;
  color: #fff !important;
  background-image: url(./images/background@2x.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center 0px;
}

.section-background-image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  overflow: hidden;
  position: absolute;
}
.header-overlay {
  background: rgba(0, 0, 0, 0.7) !important;
  height: 100%;
  color: #fff;
}

.header-section .container {
  height: 100%;
}

.position-middle {
  position: relative;
  top: 30%;
  -webkit-transform: translateY(-30%);
  transform: translateY(-30%);
  margin: 0 auto;
}

.header-section h1 {
  font-size: 3.73em;
  font-weight: 300;
  line-height: 1.2;
  color: #fff;
}

.header-section h4 {
  font-size: 1.6em;
  font-weight: 400 !important;
  color: #fff;
}

.position-bottom {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 30px;
  padding: 0 30px;
}

.button-circle-lg {
  width: 60px;
  height: 60px;
  line-height: 60px;
}

.button-circle,
a.button-circle {
  background: #e5e8eb;
  display: inline-block;
  border: 2px solid #e5e8eb;
  border-radius: 50%;
  color: #131517;
  text-align: center;
  -webkit-transition: ease-out 0.12s;
  transition: ease-out 0.12s;
}

.button-circle-outline-white,
a.button-circle-outline-white {
  background: 0 0;
  border-color: rgba(255, 255, 255, 0.3);
  color: #fff;
}

.arrow {
  color: rgba(255, 255, 255, 0.3);
}

.arrow-up {
  color: #343434;
}

@media (max-width: 991px) {
  :root {
    --header-height: 72px;
  }
  .header .header-menu {
    position: fixed;
    top: 0;
    left: -100%;
    overflow-y: auto;
    background: #fff;
    width: 300px;
    height: 100%;
    -webkit-box-shadow: 0 0 36px 0 rgba(22, 25, 28, 0.17);
    box-shadow: 0 0 36px 0 rgba(22, 25, 28, 0.17);
    padding: 20px 30px;
    -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  .header .header-menu .nav {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .header .header-menu .nav .nav-item {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
    border-bottom: 1px solid #e3e6e9;
    padding: 14px 0;
  }
  .header .header-menu .nav .nav-item .nav-link {
    line-height: initial;
    display: inline-block;
    width: auto;
    height: auto;
    color: #7c7c7c;
  }

  .header {
    height: var(--header-height);
  }
}

@media (min-width: 992px) {
  .header .header-toggle {
    display: none;
  }
}

.section,
.section-md {
  padding: 100px 0;
}

@media (max-width: 768px) {
  .section,
  .section-md {
    padding: 70px 0;
  }

  .header-section h1 {
    font-size: 2.2em;
  }

  .header-section h4 {
    font-size: 1.1em;
  }
  .container {
    padding: 0 30px !important;
  }
}

.alternate-section {
  background: #f5f5f5;
}

.technology-container {
  margin-bottom: 30px !important;
}

.margin-bottom-70 {
  margin-bottom: 70px;
  @media (max-width: 768px) {
    margin-bottom: 35px;
  }
}

img.service-icon {
  height: 64px;
  width: 64px;
}

h2 {
  font-size: 2.13em;
  line-height: 1.625;
  color: #343434;
}

p {
  margin: 0;
  line-height: 1.86;
}

.left-align {
  text-align: left;
}

ul[class*=" list-"],
ul[class^="list-"] {
  padding: 0;
}

ul.feature-check {
  margin-top: 15px;
}

ul.list-icon li {
  position: relative;
  padding-left: 24px;
  list-style-type: none;
  margin: 0 0 7px;
}

ul.list-icon.list-icon-check li:before {
  content: "\f00c";
}

ul.list-icon li:before {
  position: absolute;
  top: 0;
  left: 0;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.service-container {
  margin-bottom: 50px !important;
}

.margin-top-20 {
  margin-top: 20px;
}

.separator {
  margin-top: 30px;
  height: 1px;
  border-bottom: 1px solid #ff8d00;
}

.footer-dark {
  background: #131517 !important;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
}

.footer-dark a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
}

.footer-section-sm {
  padding: 30px 0;
}

.col-spacing-10 {
  margin-right: -5px;
  margin-bottom: -10px;
  margin-left: -5px;
}

@media (min-width: 768px) {
  .text-md-right {
    text-align: right !important;
  }
  .text-md-left {
    text-align: left !important;
  }
}

.col-spacing-10 div[class^="col-"]:not(.row) {
  margin-bottom: 10px;
  padding: 0 5px;
}

.grecaptcha--tac {
  font-size: 10px;
}

.footer-section-sm p {
  margin: 0;
}

.social-link {
  padding: 2px 10px;
}

.social-link:last-child {
  padding-right: 0;
}

.scrolltotop {
  position: fixed;
  right: 20px;
  bottom: 20px;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  z-index: 992;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: ease-out 0.12s;
  transition: ease-out 0.12s;
}

.scrolltotop.scrolltotop-show {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

.button-circle-dark,
a.button-circle-dark {
  background: #ff8d00;
  border: 0;
  color: #343434 !important;
  text-align: center;
  -webkit-transition: ease-out 0.12s;
  transition: ease-out 0.12s;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.8;
}

.button-circle-sm {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.row div[class^="col-"]:not(.row) {
  margin-bottom: 30px;
}

footer .row div[class^="col-"]:not(.row) {
  margin-bottom: 10px;
}
